<template>
  <div class="row">
    <input-text
      v-model="filtro.titulo"
      class="col-12 col-md-6"
      :label="$t('modulos.clausula_contratual.filtro.titulo')"
      em-filtro
    />
    <input-select
      v-model="filtro.classificacao"
      :options="opcoes.classificacao"
      class="col-12 col-md-6"
      :label="$t('modulos.clausula_contratual.filtro.classificacao')"
      em-filtro
      retornar-objeto
    />
  </div>
</template>
<script>
import EnumeradorService from "@common/services/cadastros/EnumeradorService";
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        classificacao : []
      },
    };
  },
  mounted(){
    this.buscarClassificacao();
  },
  methods: {
    buscarClassificacao : function(){
      EnumeradorService.buscar('EnumClassificacaoClausulaContratual').then((res) => {
        this.opcoes.classificacao = res
      });
    },
  },
};
</script>

